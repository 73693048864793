<template>
	<section class="layout"></section>
</template>

<script>
	export default {
		data() {
			return {
				version: process.env.VUE_APP_VERSION
			}
		},

		mounted() {
			if ( typeof this.$route.query !== 'undefined' && typeof this.$route.query.token !== 'undefined' ) {
				this.$store.commit( 'token', this.$route.query.token );
				this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token;

				this.axios.get( 
					'/competitor-portal/user'
				)
				.then( response => {
					if ( 200 === response.status ) {
						if ( response.data.data ) {
							this.$store.commit( 'advntm_token', Math.ceil( Math.random() * 1000000 ) );
							
							response.data.data.VUE_APP_VERSION = this.version;
							this.$store.commit( 'user_update', response.data.data );

							this.$router.push( { name: 'Dashboard' } );
						}
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching your profile.' } );
					this.$emit( 'view_loaded', null );
				} );
			}
		}
	}
</script>