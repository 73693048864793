<template>
	<section class="login flex items-center">
		<div v-if="false === user_type" class="container mx-auto sm:w-100 md:w-8/12 xl:w-4/12 px-12">
			<div class="w-auto mx-auto text-mono-lightest">
				<inline-svg class="login-logo mb-24 w-auto h-20 ml-auto mr-auto fill-current" :src="require( '@/' + $functions.getResourceUrl( $functions.getSiteData( 'logo', 'src' ) ) )" />
			</div>

			<div v-if="'' !== $route.name" class="mb-9">
				<Title tag="h1" text="Welcome" />
			</div>

			<p v-html="$functions.getSiteData( 'pre_login', 'title' )"></p>

			<Message class="mt-8" />

			<div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-y-8">
				<div>
					<Button @click="change_user_type('runner')" text="I am a runner" class="w-auto whitespace-nowrap" />
				</div>

				<div>
					<Button @click="change_user_type('volunteer')" text="I am a volunteer" class="w-auto whitespace-nowrap" />
				</div>
			</div>

			<p class="text-sm mt-16">Need help? Contact <a :href="'mailto:' + $functions.getSiteData('support_email', 'main') + '?subject=Help creating a magic login link'">support</a>.</p>
		</div>
		
		<div v-else class="container mx-auto sm:w-100 md:w-8/12 xl:w-4/12 px-12">
			<div class="w-auto mx-auto text-mono-lightest">
				<inline-svg class="login-logo mb-24 w-auto h-20 ml-auto mr-auto fill-current" :src="require( '@/' + $functions.getResourceUrl( $functions.getSiteData( 'logo', 'src' ) ) )" />
			</div>

			<div v-if="'' !== $route.name" class="mb-9">
				<button v-show="typeof fixed_user_type === 'undefined' || false === fixed_user_type" class="w-auto hover:text-advntm-highlight" @click="change_user_type(false)">&larr; Go back</button>

				<Title tag="h1" :text="$route.name" />
			</div>

			<p v-html="login_text"></p>

			<Message class="mt-8" />

			<form @submit.prevent="login( $event )" class="mt-16">
				<div class="form-control">
					<InputText required v-model="email" name="email" id="email" label="Email Address" :valueString="email" />
				</div>
				
				<div class="form-control mt-9">
					<Button :disabled="email_has_no_value" id="login" text="Send me a login link" class="w-full" />

					<div class="text-xs mt-4">Once sent, you will receive an email within the next 10-15 minutes, with instructions on what to do next, please also check your junk folder.</div>

					<p class="text-sm mt-9">Need help? Contact <a :href="'mailto:' + $functions.getSiteData('support_email', 'main') + '?subject=Help creating a magic login link'">support</a>.</p>
				</div>
			</form>
		</div>
		
		<BackgroundImage :image="background_image" />
	</section>
</template>

<script>
	export default {
		data() {
			return {
				email: null,
				user_type: false,
				fixed_user_type: false,
				origin: window.location.origin,
				login_text: this.$functions.getSiteData( 'login', 'title' ),
				background_image: this.$functions.getSiteData( 'login', 'background' )
			}
		},

		computed: {
			email_has_no_value: function( ) {
				if ( null === this.email ) {
					return true;
				}

				if ( typeof this.email !== 'undefined' && this.email.length > 5 ) {
					return false;
				}

				return true;
			}
		},

		methods: {
			change_user_type: function(type) {
				this.user_type = type;

				let current_site 	= this.$functions.getCurrentSite();

				if (false === type) {
					type 	= 'generic';
				}

				if ('volunteer' === type) {
					this.login_text = this.$functions.getVolunteerSite().login.title;
				} else {
					this.login_text = this.$functions.getCurrentSite().login.title;
				}

				this.background_image = current_site.pre_login.backgrounds[ type ];
			},
			login: function( event ) {	
				let current_site = this.$functions.getCurrentSite();

				// if the user is a volunteer.
				if ('volunteer' === this.user_type) {
					// if we have a matched site, and the site has a volunteer portal enabled. 
					if (null !== current_site && ('undefined' !== typeof current_site.has_volunteer_portal || false !== current_site.has_volunteer_portal)) {

						if (current_site.domain.volunteer_portal.includes('https://')) {
							this.origin = current_site.domain.volunteer_portal;
						} else {
							this.origin = 'https://' + current_site.domain.volunteer_portal;
						}
					}
				}

				let button = event.srcElement.querySelector('button');

				if ( button ) {
					button.querySelector('span').textContent = 'Please wait...';
					button.setAttribute('disabled', true);
				}

				this.axios.post( 
					'/competitor-portal/login-email',
					{
						"email": this.email,
						"redirectUrl": this.origin + '/auth?token='
					}
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, text: 'Success! If your email exists in our database, you will receive a login link shortly. Please check your junk folder.'} );

						if ( button ) {
							button.querySelector('span').textContent = 'Check your email';
						}
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					if ( button ) {
						button.querySelector('span').textContent = 'Send me a login link...';
						button.removeAttribute('disabled');
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem creating a login link.' } );
				} );
			}
		},

		created() {
			this.$emit( 'view_loaded', true );

			let current_site = this.$functions.getCurrentSite();

			if ('undefined' !== typeof current_site.pre_login && ('undefined' !== typeof current_site.pre_login.backgrounds || Object.values(current_site.pre_login.backgrounds).length > 0)) {
				this.background_image = current_site.pre_login.backgrounds[ 'generic' ];
				document.getElementById('global-theme-background').style.display = 'none';
			}

			if ('undefined' === typeof current_site.has_volunteer_portal || false === current_site.has_volunteer_portal) {
				this.user_type = 'runner';
				if ('undefined' !== typeof current_site.pre_login && ('undefined' !== typeof current_site.pre_login.backgrounds || Object.values(current_site.pre_login.backgrounds).length > 0)) {
					this.background_image = current_site.pre_login.backgrounds[ this.user_type ];
				}
			}

			if ( typeof this.$route.query !== 'undefined' ) {
				this.email = this.$route.query.email;
			}

			if ('undefined' !== current_site.is_volunteer_portal && true === current_site.is_volunteer_portal) {
				this.user_type = 'volunteer';
				this.fixed_user_type = true;
			}
		}
	}
</script>	

<style scoped lang="scss">
	.login {
		position: relative;
		height: 100vh;
	}

	.login-logo {
		max-width: 70vw;
		margin-inline: auto;

		@media only screen and (min-width: 600px) {
			max-width: 80%;
		}
	}
</style>