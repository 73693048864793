<template>
	<article class="questionaire">
		<router-link class="text-mono-lightest text-2xl inline-block mb-4" :to="{ name: 'Questionnaire', params: { id: data.id }, query: { category: data.category, race_id: data.race_id } }" v-html="data.category + ' &rarr;'" />

		<div class="flex space-x-8">
			<div class="text-sm"> 
				<div class="mb-1 text-mono-dark font-bold">Status:</div>

				<div class="flex items-center">
					<CheckIcon v-if="'Submitted' === data.status" class="w-6 h-6 mr-2 text-state-success" />
					<strong class="questionaire-status" :class="data.status" v-html="data.status"></strong>
				</div>
			</div>

			<div v-if="data.due_on !== null" class="text-sm"> 
				<div class="mb-1 text-mono-dark font-bold">Due:</div>

				<div class="flex items-center">
					<time v-html="$functions.daysUntil( data.due_on )"></time>
				</div>
			</div>
		</div>
	</article>
</template>	

<script>
	import { CheckIcon} from '@heroicons/vue/solid';

	export default {
		name: 'Questionnaire',

		components: {
			CheckIcon,
		},

		props: {
			data: {
				type: Object,
				default: function() {
					return {};
				}
			}
		},

		mounted() {
			// console.log( this.data );
		}
	}
</script>