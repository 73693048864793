<template>
	<section v-if="open === true" class="bg-advntm-highlight">
		<div class="py-3 px-3 sm:px-5">
			<div class="flex items-center justify-between flex-wrap">
				<div class="w-0 flex-1 flex items-center pr-4">
					<span class="flex p-2 rounded-lg bg-indigo-800">
						<SpeakerphoneIcon class="h-6 w-6 text-mono-darkest" aria-hidden="true" />
					</span>
					<p class="ml-3 font-light text-mono-darkest truncate">
						<span v-html="message"></span>
					</p>
				</div>

				<div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
					<router-link v-if="( typeof link_text !== 'undefined' && typeof link !== 'undefined' )" class="sm:block text-mono-darkest text-md hover:bg-advntm-highlight hover:no-underline hover:text-mono-darkest" :to="{ path: link }" v-html="link_text + ' &rarr;'" />
				</div>

				<div v-if="true === show_cancel" class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
					<button v-on:click="open = false" type="button" class="-mr-1 leading-none h-auto flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
						<span class="sr-only">Dismiss</span>
						<XIcon class="h-6 w-6 text-mono-darkest hover:text-mono-mid" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { SpeakerphoneIcon, XIcon } from '@heroicons/vue/outline'

	export default {
		name: 'Notification',

		props: {
			message: null,
			link: null,
			link_text: null,
			show_cancel: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				open: true
			}
		},

		components: {
			SpeakerphoneIcon,
			XIcon,
		}
	}
</script>