<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<nav v-if="null !== pages" class="flex" aria-label="Breadcrumb">
		<ol role="list" class="flex items-center space-x-2">
			<li>
				<div>
					<router-link class="text-mono-dark hover:text-advntm-accent" :to="{ 'name': 'Dashboard' }">
						<HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
						<span class="sr-only">Home</span>
					</router-link>
				</div>
			</li>

			<li v-for="page in pages" :key="page.name">
				<div class="flex items-center text-mono-dark">
					<svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
						<path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
					</svg>

					<router-link :to="{ path: page.path }" class="ml-4 text-sm font-medium text-mono-mid hover:text-advntm-accent" :aria-current="page.current ? 'page' : undefined">{{ page.meta.name ? page.meta.name : page.name }}</router-link>
				</div>
			</li>
		</ol>
	</nav>
</template>

<script>
	import { HomeIcon } from '@heroicons/vue/outline'
	export default {
		name: 'Breadcrumbs',

		components: {
			HomeIcon,
		},

		data() {
			return {
				pages: null
			}
		}, 

		watch: {
			'$route': function() {
				if ( this.$route ) {
					this.pages = [];

					if ( typeof this.$route.meta.parent !== 'undefined' && false !== this.$route.meta.parent ) {
						let parent = this.$router.getRoutes().find( route => this.$route.meta.parent === route.name );

						if ( parent ) {
							this.pages.push( parent );
						}

						this.pages.push( this.$route )
					} else {
						this.pages.push( this.$route )
					}
				}
			}
		}
	}
</script>