<template>
	<div v-if="null !== image && false !== image" class="theme-background" :style="'background-image: url( ' + require( '@/' + $functions.getResourceUrl( image ) ) + ' );'"></div>
</template>

<script>
	export default {
		name: 'BackgroundImage',

		props: [ 'image' ]
	}
</script>

<style scoped lang="scss">
	.theme-background {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		mix-blend-mode: hard-light;
		opacity: 0.11;
	}
</style>