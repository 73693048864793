<template>
	<section v-if="false !== user" class="home">
		<div class="mt-9 mb-16">
			<p>Welcome back <strong v-html="user.first_name"></strong>.</p>
		</div>

		<div v-if="Object.values( empty_fields ).length > 0">
			<Notification v-if="Object.values( empty_fields ).length === 1" 
				:message="'There is <strong>' + Object.values( empty_fields ).length + '</strong> required field to complete on your profile'"
				link="/profile" link_text="Profile" :show_cancel="true" />
			<Notification v-else 
				:message="'There are <strong>' + Object.values( empty_fields ).length + '</strong> required fields to complete on your profile'"
				link="/profile" link_text="Profile" :show_cancel="true" />
		</div>

		<hr class="border-mono-darker">

		<div v-if="false !== closest_race">
			<section v-if="null !== closest_race && Object.values( closest_race ).length > 0" class="closest-race mt-16">
				<Title v-if="false === $store.getters.is_volunteer" tag="h2" text="Next Race" :classes="[ 'text-mono-lightest', 'text-lg', 'mb-9' ]" />
				<Title v-else tag="h2" text="Next Event" :classes="[ 'text-mono-lightest', 'text-lg', 'mb-9' ]" /> 

				<Race v-if="false !== closest_race && endpoint !== 'volunteer-portal/events'" :race="closest_race" />
				<Event v-if="false !== closest_race && endpoint === 'volunteer-portal/events'" :race="closest_race" />

				<div class="mt-9">
					<router-link :to="{ name: 'Races' }">All races &rarr;</router-link>
				</div>
			</section>

			<div v-if="( null === closest_race || false === closest_race ) || Object.values( closest_race ).length <= 0 " class="mt-16">
				<Title v-if="false === $store.getters.is_volunteer" tag="h2" text="Next Race" :classes="[ 'text-mono-lightest', 'text-lg', 'mb-9' ]" />
				<Title v-else tag="h2" text="Next Event" :classes="[ 'text-mono-lightest', 'text-lg', 'mb-9' ]" /> 
				
				<p class="text-state-info flex">
					<InformationCircleIcon class="h-8 w-8 text-mono-white mr-4" aria-hidden="true" />

					<span v-if="false === $store.getters.is_volunteer">It looks like you haven't got any upcoming races.</span>
					<span v-else>It looks like you haven't got any upcoming events.</span>
				</p>
			</div>
		</div>
	</section>
</template>

<script>
	import { InformationCircleIcon } from '@heroicons/vue/outline';

	export default {
		name: 'Home',

		components: {
			InformationCircleIcon
		},

		data() {
			return {
				user: false,
				stats: [],
				link: [],
				closest_race: false,
				empty_fields: null,
				endpoint: false
			}
		},

		created() {
			if ( this.$store.getters.user ) {
				this.axios.get( 'competitor-portal/user' )
				.then( response => {
					if ( 200 === response.status ) {
						this.user = response.data.data;

						let empty_fields = Object.values( this.user ).filter( ( field, index ) => ( null === field || "" === field ) && Object.keys( this.user )[index] !== 'dietary_preferences_other' );
						this.empty_fields = empty_fields;
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching your profile.' } );
					this.$emit( 'view_loaded', null );
				} );

				this.endpoint = 'competitor-portal/races';

				if (true === this.$store.getters.is_volunteer) {
					this.endpoint = 'volunteer-portal/events';
				}	

				this.axios.get(
					this.endpoint
				)
				.then( response => {
					if ( 200 === response.status ) {
						if ('object' === typeof response.data.events && Object.values(response.data.events).length > 0) {
							this.closest_race = response.data.events.filter( race => this.$functions.days( race.event.start_date ) > 0 ? race : false );

							if (Object.values(this.closest_race).length > 0) {
								this.closest_race = this.closest_race[0];
							}
						} else {
							if ( 'undefined' === typeof response.data || Object.values( response.data.data ) <= 0 ) {
								this.closest_race = null;	
							}

							if ( Object.values( response.data.data ).length > 0 ) { 
								/*
								link
								*/
								this.link = {
									title: 'View races',
									url: {
										path: '/races'
									}
								};

								/*
								closest_race
								*/

								this.closest_race = response.data.data.filter( race => this.$functions.days( race.starts_at ) > 0 ? race : false );

								if (Object.values(this.closest_race).length > 0) {
									this.closest_race = this.closest_race[0];
								}
							}
						}

						// load the view
						this.$emit( 'view_loaded', true );
					}
				} )
				.catch( error => {
					if ( 'undefined' !== typeof error.response && 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
						this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching your dashboard stats.' } );
					}
					
					this.$emit( 'view_loaded', true );
				} );
			}
		}
	}
</script>
