<template>
	<section class="notifications" id="notifications">
		<!-- <Title tag="h2" text="Notifications" :classes="[ 'text-mono-lightest', 'text-3xl', 'mb-7' ]" /> -->
		<Notification class="mb-5" />
	</section>
</template>

<script>
	export default {
		name: 'Notifications'
	}
</script>