<template>
	<section class="bg-mono-darkest flex items-center">
		<div class="py-8 sm:py-16 w-full">
			<main class="app-wrapper">
				<div class="container mx-auto px-8 flow-loose max-w-xl">
					<div class="title mb-9">
						<Title tag="h1" class="font-bold" :classes="[ 'text-mono-lightest', 'text-5xl', 'mb-0' ]" :text="'Update required'" />
					</div>

					<p>An important update is required to ensure your Runner Portal is running efficiently.</p>

					<div class="text-sm sm:flex items-center">
						<div class="pr-4 mb-3 sm:mb-0">
							<ExclamationIcon class="h-12 w-12 text-state-danger"></ExclamationIcon>
						</div>

						<span>
							Before we can install the update, we need to log you out. We'll send you a login link when we are ready.
						</span>
					</div>

					<Button @click="install_update" id="login" text="Install the update" class="w-full" />
				</div>
			</main>
		</div>

		<BackgroundImage :image="$functions.getSiteData( 'body', 'background' )" />

		<Message />
	</section>	
</template>

<script>
	import { ExclamationIcon } from '@heroicons/vue/outline'
	
	export default {
		name: 'UpdateRequired',

		components: {
			ExclamationIcon
		},

		methods: {
			install_update: function() {
				this.axios.post( 
					'/competitor-portal/login-email',
					{
						"email": this.$store.getters.user.email,
						"redirectUrl": window.location.origin + '/auth?token='
					}
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, text: 'The update has been installed, a login link has been sent. Logging you out...' } );

						setTimeout( () => {
							localStorage.removeItem( 'user' );	
							localStorage.removeItem( 'advntm_token' );	
							localStorage.removeItem( 'token' );	

							this.$forceUpdate();
							window.location.reload( true );
						}, 2000 );
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem creating a login link.' } );
				} );
			}
		}
	}
</script>

<style lang="scss" scoped>
	section	{
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.flow-loose {
		& > * + * {
			margin-bottom: 0;
			margin-top: 2rem;
		}
	}

	span {
		margin-top: -7.5px;
	}
</style>