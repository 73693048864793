<template>
	<div class="InputText">
		<label class="text-sm mb-3" :for="id || false" v-html="label"></label>
		<input ref="input" @input="handleInput" class="focus:ring-advntm-accent focus:border-advntm-accent block w-full pl-5 pr-10 text-mono-darkest font-light" type="text" :name="name || false" :id="id || false" :value="value">
	</div>
</template>

<script>
	export default {
		name: 'InputText',

		data() {
			return {
				value: ''
			}
		},

		props: [ 'label', 'name', 'id', 'valueString' ],

		emits: [ 'update:modelValue' ],

		mounted() {
			if ( typeof this.$refs.input.value !== 'undefined' && '' !== this.$refs.input.value ) {
				this.value = this.$refs.input.value;
			}

			if ( typeof this.valueString !== 'undefined' || false !== this.valueString || '' !== this.valueString ) {
				this.value = this.valueString;
			}
		},

		methods: {
			handleInput: function() {
				this.value = this.$refs.input.value;

				if ( "" !== this.$refs.input.value ) {
					this.$emit('update:modelValue', this.$refs.input.value );
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	label {
		display: block;
		font-weight: 700;
	}
</style>