<template>
	<fieldset v-if="Object.values( items ).length > 0">
		<legend class="sr-only">Notifications</legend>
		<div class="divide-y divide-mono-darker">
			<div v-for="( item, index) in items" :key="item.id" class="relative flex items-start py-4">
				<div v-if="null !== item.completed_at">
					<div class="flex items-center justify-center rounded-full text-center mr-5">
						<CheckCircleIcon class="mt-0.25 w-8 h-8 text-state-success" />
					</div>
				</div>

				<div v-else>
					<div class="flex items-center justify-center rounded-full text-center mr-5">
						<ExclamationIcon class="mt-0.25 w-8 h-8 text-state-danger" />
					</div>
				</div>

				<div class="min-w-0 flex-1 text-sm">
					<label :for="attr + '_' + item.id" class="font-medium text-lg text-mono-lightest" v-html="item.description"></label>

					<p v-if="null !== item.completed_at" class="flex text-xs text-mono-mid">Completed {{ $functions.daysUntil( item.completed_at ) }}</p>

					<p class="text-xs text-mono-mid" v-else>Due {{ $functions.daysUntil( item.due_at ) }} <span>({{ $functions.formatDate( item.due_at ) }})</span></p>

				</div>
				<div class="ml-3 flex items-center h-5">
					<input ref="input" @input="handleInput( index )" :id="attr + '_' + item.id" aria-describedby="description" :name="attr + '_' + item.id" type="checkbox" class="focus:ring-state-success h-6 w-6 text-state-success border-mono-darkest rounded" value="true" :checked="null !== item.completed_at" />
				</div>
			</div>
		</div>
	</fieldset>
</template>

<script>
	import { ExclamationIcon, CheckCircleIcon } from '@heroicons/vue/solid';

	export default {
		name: 'Checklist',

		components: {
			ExclamationIcon,
			CheckCircleIcon,
		},

		props: {
			items: {
				type: Array,
				default: function() {
					return [];
				}
			},

			attr: {
				type: String,
				data: ''
			}
		},

		methods: {
			handleInput: function( index ) {
				this.$emit( 'input_checked', this.$refs.input[index].id.replace( this.attr + '_', '' ), this.$refs.input[index].checked );
			}
		}
	}
</script>