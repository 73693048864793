<template>
	<section v-if="false !== event" class="event min-h-full">
		<header class="md:flex md:items-center md:justify-between ">
			<div class="sm:flex items-center sm:space-x-5">
				<div class="-mb-1">
					<div class="sm:flex items-center mb-1">
						<h1 class="text-4xl font-bold text-mono-lightest mb-2" v-html="event.event.name"></h1>
					</div>

					<div class="flex items-center mt-1" v-if="null !== event.event.start_date">
						<CalendarIcon class="flex-shrink-0 mr-2 h-4 w-4 sm:h-6 sm:w-6 text-mono-mid" /> 

						<div class="flex items-center">
							<time class="text-sm sm:text-md font-light text-mono-mid">{{ $functions.formatDate( event.event.start_date ) }}</time> <span class="px-2">&mdash;</span> <time class="text-sm sm:text-md font-light text-mono-mid">{{ $functions.formatDate( event.event.finish_date ) }}</time>
						</div>
					</div>
				</div>
			</div>
		</header>

		<Notification v-if="$functions.days(event.event.start_date) <= 0 && false === event.interested_in_next_event" class="mt-12" :message="'Register your interest for next years event'" :link="'/interested-in-next-event/' + $route.params.id" link_text="Click to register" />

		<section class="py-12">
			<div class="sm:hidden">
				<label for="tabs" class="sr-only">Select a tab</label>
				<select @change="select_tab( $event )" id="tabs" name="tabs" class="text-mono-darkest block w-full focus:ring-advntm-accent focus:border-border-mono-darkest border-mono-darker">
					<option :value="tab.id" v-for="tab in tabs" :key="tab.name" :selected="active_tab === tab.id">{{ tab.name }}</option>
				</select>
			</div>
			<div class="hidden sm:block">
				<nav class="flex space-x-4" aria-label="Tabs">
					<button @click.prevent="select_tab_via_button( tab )" v-for="tab in tabs" :key="tab.name" :class="[active_tab === tab.id ? 'bg-mono-darker text-mono-lightest' : 'text-mono-dark hover:text-advntm-highlight', 'p-3 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined" class="h-auto">
						{{ tab.name }}
					</button>
				</nav>
			</div>
		</section>

		<section v-if="active_tab === 'overview'" class="pb-12">
			<div class="pb-7">
				<h2 class="text-lg leading-6 font-medium text-mono-lighter">Overview</h2>
			</div>
			
			<div class="border-t border-mono-darker pt-7">
				<dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 items-center">
					<div class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Status
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<span v-if="2 === event.volunteer_status.id" class="inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-xs font-medium text-state-success ring-1 ring-inset ring-state-success">
								<svg class="h-1.5 w-1.5 mr-2 fill-current mt-0" viewBox="0 0 6 6" aria-hidden="true">
									<circle cx="3" cy="3" r="3" />
								</svg>
								
								{{event.volunteer_status.description}}
							</span>

							<span v-else-if="3 === event.volunteer_status.id || 4 === event.volunteer_status.id" class="inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-xs font-medium text-state-danger ring-1 ring-inset ring-state-danger">
								<svg class="h-1.5 w-1.5 mr-2 fill-current mt-0" viewBox="0 0 6 6" aria-hidden="true">
									<circle cx="3" cy="3" r="3" />
								</svg>
								
								{{event.volunteer_status.description}}
							</span>

							<span v-else class="inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-xs font-medium text-state-neutral ring-1 ring-inset ring-state-neutral">
								<svg class="h-1.5 w-1.5 mr-2 fill-current mt-0" viewBox="0 0 6 6" aria-hidden="true">
									<circle cx="3" cy="3" r="3" />
								</svg>
								
								{{event.volunteer_status.description}}
							</span>
						</dd>
					</div>

					<div class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Last updated
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<ClockIcon class="flex-shrink-0 mr-2 h-6 w-6"/>
							<span class="leading-normal">{{ $functions.formatDateTime( event.volunteer_status.updated_at ) }}</span>
						</dd>
					</div>

					<div class="race-detail col-span-3">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Background information
						</dt>

						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							{{event.background_information}}
						</dd>

					</div>

					<div class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Category
						</dt>

						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							{{event.category}}
						</dd>
					</div>

					<div v-if="Object.values(event.skills).length > 0" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Skills
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<span v-for="(skill, index) in Object.values(event.skills)" :key="index">
								<span v-if="Object.values(event.skills).length === 1">
									{{skill.name}} 
								</span>

								<span v-else>
									<span v-if="Object.values(event.skills).length === (index + 1)">
										&nbsp;and {{skill.name}}
									</span>

									<span v-else-if="Object.values(event.skills).length - 1 === (index + 1)">
										{{skill.name}}
									</span>

									<span v-else>
										{{skill.name}}, 
									</span>
								</span>
							</span>
						</dd>
					</div>

					<div v-if="null !== event.role_preferences && Object.values(event.role_preferences).length > 0" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Role Preferences
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<span v-for="(role, index) in Object.values(event.role_preferences)" :key="index">
								<span v-if="Object.values(event.role_preferences).length === 1">
									<span style="text-transform: capitalize;">{{$functions.unsanitize(role)}} </span>
								</span>

								<span v-else>
									<span v-if="Object.values(event.role_preferences).length === (index + 1)">
										&nbsp;and {{role}}
									</span>

									<span v-else-if="Object.values(event.role_preferences).length - 1 === (index + 1)">
										{{role}}
									</span>

									<span v-else>
										{{role}}, 
									</span>
								</span>
							</span>
						</dd>
					</div>
				</dl>
			</div>
		</section>

		<section v-if="active_tab === 'availability'" class="pb-12">
			<div class="pb-7 sm:flex space-y-5 sm:space-y-0 items-center justify-between">
				<h2 class="text-lg leading-6 font-medium text-mono-lighter">Availability</h2>

				<div>
					<router-link :to="{ name: 'Availability', params: { id: event.id } }" v-html="'Manage availability &rarr;'" />
				</div>
			</div>

			<div class="border-t border-mono-darker pt-7">
				<dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 items-center">
					<div class="race-detail col-span-3">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Availability
						</dt>

						<dd class="mt-1 text-md text-mono-lightest grid items-center">
							<div class="col-span-2 grid grid-cols-2 gap-x-4 gap-y-6 items-center">
								<div class="inline-flex items-center" :class="1 === slot.is_available ? 'text-state-success' : 'text-state-danger'" v-for="(slot, index) in slots" :key="index">
									<CheckCircleIcon v-if="1 === slot.is_available" class="flex-shrink-0 mr-2 h-6 w-6"/>
									<XCircleIcon v-else class="flex-shrink-0 mr-2 h-6 w-6"/>

									<span>
										{{slot.availability_date}}

										<span v-if="slot.slot.includes('am')">(Day shift)</span>
										<span v-else-if="slot.slot.includes('pm')">(Night shift)</span>
									</span>
								</div>
							</div>	
						</dd>
					</div>
				</dl>
			</div>
		</section>

		<section v-if="active_tab === 'expenses'" class="pb-12">
			<div class="pb-7 sm:flex space-y-5 sm:space-y-0 items-center justify-between">
				<h2 class="text-lg leading-6 font-medium text-mono-lighter">Expense Claims</h2>

				<div>
					<router-link :to="{ name: 'NewClaim', params: { id: $route.params.id } }" v-html="'New expense claim &rarr;'" />
				</div>
			</div>

			<div class="border-t border-mono-darker pt-7">
				<ul v-if="null !== event.claims" role="list" class="divide-y divide-mono-darker">
					<li v-for="claim in event.claims" :key="claim.id" class="relative sm:flex items-center sm:space-x-4 space-y-4 sm:space-y-0 py-4">
						<div class="min-w-0 flex-auto">
							<div class="flex items-center gap-x-3">
								<div class="flex-none rounded-full p-1">
									<div class="h-2 w-2 rounded-full bg-current" :class="null === claim.approved_at ? 'bg-state-neutral' : 'bg-state-success'" />
								</div>

								<h2 class="min-w-0 text-md font-semibold leading-6 text-white">
									<router-link v-if="null === claim.approved_at" class="text-mono-lightest" :to="{ name: 'Claim', params: { id: $route.params.id, claim_id: claim.id } }" v-html="claim.description" />

									<span v-else v-html="claim.description"></span>
								</h2>
							</div>
							<div class="mt-3 flex items-center gap-x-2.5 sm:gap-x-5 leading-5 text-sm font-medium text-mono-light">
								<p v-if="null !== claim.approved_at" class="whitespace-nowrap text-state-success">
									<CheckCircleIcon class="inline-block h-4 w-4 text-current"/>

									{{$functions.formatDate(claim.approved_at)}}
								</p>

								<svg v-if="null !== claim.approved_at" viewBox="0 0 2 2" class="h-1 w-1 flex-none text-sm font-medium fill-current mt-0.15">
									<circle cx="1" cy="1" r="1" />
								</svg>

								<p class="truncate capitalize">{{ $functions.unsanitize(claim.category) }}</p>

								<svg viewBox="0 0 2 2" class="h-1 w-1 flex-none text-sm font-medium fill-current mt-0.15">
									<circle cx="1" cy="1" r="1" />
								</svg>
								
								<p class="whitespace-nowrap">&pound;{{ claim.amount }}</p>

								<svg v-if="null !== claim.filename" viewBox="0 0 2 2" class="h-1 w-1 flex-none text-sm font-medium fill-current mt-0.15">
									<circle cx="1" cy="1" r="1" />
								</svg>
								
								<p v-if="null !== claim.filename" class="whitespace-nowrap">
									<PaperClipIcon class="inline-block h-4 w-4 text-current"/>
									{{ claim.filename }}
								</p>
							</div>
						</div>
						<div class="flex items-center space-x-4">
							<span class="inline-block rounded-full flex-none py-2 px-4 text-xs font-medium ring-1 ring-inset" :class="null === claim.approved_at ? 'text-state-neutral ring-state-neutral' : 'text-state-success ring-state-success'" v-html="null === claim.approved_at ? 'Awaiting approval' : 'Approved'"></span>

							<router-link v-if="null === claim.approved_at" class="text-mono-lightest" :to="{ name: 'Claim', params: { id: $route.params.id, claim_id: claim.id } }">
								<ChevronRightIcon class="h-5 w-5 flex-none text-sm font-medium text-mono-light"/>
							</router-link>

						</div>
					</li>
				</ul>
			</div>
		</section>

		<section v-if="1 === event.has_vehicle && active_tab === 'vehicle'" class="pb-12">
			<div class="pb-7">
				<h2 class="text-lg leading-6 font-medium text-mono-lighter">Vehicle</h2>

				<p class="text-sm">To update your vehicle details, make a selection below.</p>
			</div>

			<div class="border-t border-mono-darker pt-7">
				<dl class="grid grid-cols-1 gap-x-4 gap-y-8 items-center">
					<div class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Do you have your own vehicle?
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<div class="col-span-12" v-if="vehicle_information.vehicle_type">
								<select @change="update_volunteer_details" v-model="volunteerDetails.vehicle_type" class="text-mono-darkest block w-full focus:ring-advntm-accent focus:border-border-mono-darkest border-mono-darker">
									<option value="null">-- Make a selection --</option>

									<option :selected="event.vehicle_type === vehicle" v-for="vehicle in vehicle_information.vehicle_type" :key="vehicle">
										{{vehicle}}
									</option>								
								</select>
							</div>
						</dd>
					</div>

					<div class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Is your vehicle insured for use while volunteering and are you happy to use it?
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<!-- <CheckCircleIcon v-if="1 === event.is_vehicle_insured" class="flex-shrink-0 mr-2 h-6 w-6"/> -->
							<!-- <XCircleIcon v-else class="flex-shrink-0 mr-2 h-6 w-6"/> -->

							<select @change="update_volunteer_details" v-model="volunteerDetails.is_vehicle_insured" class="text-mono-darkest block w-full focus:ring-advntm-accent focus:border-border-mono-darkest border-mono-darker">
								<option selected="selected" value="null">-- Make a selection --</option>

								<option v-for="option in vehicle_information.is_vehicle_insured" :key="option">
									{{option}}
								</option>								
							</select>
						</dd>
					</div>
				</dl>
			</div>
		</section>

		<section v-if="active_tab === 'downloads'" class="pb-12">
			<div class="pb-7">
				<h2 class="text-lg leading-6 font-medium text-mono-lighter">Downloads</h2>
			</div>

			<div class="border-t border-mono-darker pt-7">
				<dl v-if="Object.values( event.assets ).length > 0" class="mt-9 pb-9">
					<dd class="mt-1 text-sm text-mono-lighter">
						<ul role="list" class="border border-mono-darker rounded-md divide-y divide-mono-darker">
							<li v-for="pack in event.assets" :key="pack.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
								<div class="w-0 flex-1 flex items-center">
									<PaperClipIcon class="flex-shrink-0 h-5 w-5 text-mono-dark"/>
									<span class="ml-2 flex-1 w-0 truncate text-lg font-medium">{{ pack.description }}</span>
								</div>

								<div class="ml-4 flex-shrink-0">
									<div v-if="$functions.isMobile()">
										<a target="_blank" :href="pack.asset_file_url" class="font-medium text-advntm-accent hover:text-advntm-highlight">
											<span v-if="'URL' === pack.type">Open URL &rarr;</span>
											<span v-else>Download</span>
										</a>
									</div>
									<div v-else>
										<a target="_blank" :href="pack.asset_file_url" download class="font-medium text-advntm-accent hover:text-advntm-highlight">
											<span v-if="'URL' === pack.type">Open URL &rarr;</span>
											<span v-else>Download</span>
										</a>
									</div>
								</div>
							</li>
						</ul>
					</dd>
				</dl>

				<p v-else class="text-state-info flex">
					<InformationCircleIcon class="h-8 w-8 text-mono-white mr-4"/>

					<span>There are no assets to download.</span>
				</p>
			</div>
		</section>
	</section>
</template>

<script>
	import { PaperClipIcon, ChevronRightIcon, CalendarIcon, ClockIcon, InformationCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline';

	export default {
		data() {
			return {
				event: false,	
				volunteerDetails: {},
				tabs: [
					{ 
						name: 'Overview', 
						id: 'overview'
					},
					{ 
						name: 'Availability', 
						id: 'availability'
					},
					{ 
						name: 'Expense Claims', 
						id: 'expenses'
					},
					{ 
						name: 'Vehicle', 
						id: 'vehicle'
					},
					{ 
						name: 'Downloads', 
						id: 'downloads'
					}
				],
				vehicle_information: {
					'vehicle_type': [
						'No',
						'Yes - Small car with 3 or fewer passenger seats',
						'Yes - Large car with 4 or more passenger seats',
						'Yes - Campervan, or other comfortable vehicle for roadside roles.',
						'Yes - Van',
						'Yes - 4x4',
					],
					'is_vehicle_insured': [
						'No',
						'Yes'
					]
				},
				active_tab: 'overview',
				slots: []
			}
		},

		components: {
			CalendarIcon,
			CheckCircleIcon,
			ChevronRightIcon,
			PaperClipIcon,
			ClockIcon,
			XCircleIcon,
			InformationCircleIcon,
		},

		methods: {
			select_tab: function( event ) {
				let found_tab = this.get_tab( event.srcElement.value );

				if ( null === found_tab ) {
					return false;
				}

				this.active_tab = found_tab.id;
			},

			select_tab_via_button: function( tab ) {
				let found_tab = tab;

				if ( null === found_tab ) {
					return false;
				}

				this.active_tab = tab.id;
			},

			update_volunteer_details: function() {
				let skills 		= [];

				this.volunteerDetails.has_vehicle = true;

				Object.values(this.event.skills).forEach(skill => {
					skill.is_selected = true;
					skills.push(skill);
				});

				if (typeof this.volunteerDetails.is_vehicle_insured !== 'undefined') {
					if ('Yes' === this.volunteerDetails.is_vehicle_insured) {
						this.volunteerDetails.is_vehicle_insured = 1;
					} else {
						this.volunteerDetails.is_vehicle_insured = 0;
					}
				}

				this.volunteerDetails.skills = skills;
				this.volunteerDetails.role_preferences = this.event.role_preferences;

				this.axios(
					{
						method: 'PUT',
						url: 'volunteer-portal/'+ this.$route.params.id +'/volunteer-details',
						data: this.volunteerDetails
					}
				).then( response => {
					if (200 === response.status) {
						this.$store.commit( 'message', { type: 200, text: 'Your updates were successfully saved.' } );

						if (typeof this.volunteerDetails.is_vehicle_insured !== 'undefined') {
							if (1 === this.volunteerDetails.is_vehicle_insured) {
								this.volunteerDetails.is_vehicle_insured = 'Yes';
							} else {
								this.volunteerDetails.is_vehicle_insured = 'No';
							}
						}
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem updating your details for this event.' } );
				} );
			}
		},

		mounted() {
			if (typeof this.$route.query.interested !== 'undefined' && 'true' === this.$route.query.interested) {
				this.$store.commit( 'message', { type: 200, text: 'Thank you, your interest for the next event has been registered.' } );
			}

			this.axios.get( 'volunteer-portal/events/' + this.$route.params.id )
			.then( response => {
				this.event = response.data.volunteer;
				this.slots = this.event.availability_slots;

				if (this.event.vehicle_type) {
					this.volunteerDetails.vehicle_type = this.event.vehicle_type;
				}

				if (typeof this.event.is_vehicle_insured !== 'undefined') {
					if (1 === this.event.is_vehicle_insured) {
						this.volunteerDetails.is_vehicle_insured = 'Yes';
					} else {
						this.volunteerDetails.is_vehicle_insured = 'No';
					}
				}

				this.axios.get( 'volunteer-portal/' + this.$route.params.id + '/' + this.event.event.id + '/assets' )
				.then( response => {
					this.event.assets = response.data.data;
					this.$emit( 'view_loaded', true );
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching the race assets.' } );
					this.$emit( 'view_loaded', null );
				} );

				this.$emit( 'view_loaded', true );
			} )
			.catch( error => {
				if ( 401 === error.response.status ) {
					this.$functions.handleUnauthorised();
				}

				this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching this event.' } );
				this.$emit( 'view_loaded', null );
			} );
		}
	}
</script>