import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
		meta: {
			name: 'Dashboard',
			nav: true,
			landing: false,
			userNav: true,
			searchable: false,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: null
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: {
			name: 'Login',
			nav: false,
			landing: true,
			userNav: false,
			searchable: false,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/auth',
		name: 'Auth',
		component: () => import(/* webpackChunkName: "login" */ '../views/Auth.vue'),
		meta: {
			name: 'Login',
			nav: false,
			landing: true,
			userNav: false,
			searchable: false,
			hideTitle: false,
			parent: false,
			static: true,
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
		meta: {
			name: 'Your Profile',
			loading_error_label: 'your profile',
			nav: false,
			landing: false,
			userNav: true,
			searchable: false,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: null
		}
	},
	{
		path: '/races',
		name: 'Races',
		component: () => import(/* webpackChunkName: "races" */ '../views/Races.vue'),
		meta: {
			name: 'My Races',
			nav: true,
			landing: false,
			userNav: false,
			searchable: true,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/race/:id',
		name: 'Race',
		component: () => import(/* webpackChunkName: "race" */ '../views/Race.vue'),
		meta: {
			name: 'Race',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: 'Races',
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/events',
		name: 'Events',
		component: () => import(/* webpackChunkName: "revents" */ '../views/Events.vue'),
		meta: {
			name: 'My Events',
			nav: true,
			landing: false,
			userNav: false,
			searchable: true,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: true
		}
	},
	{
		path: '/event/:id',
		name: 'Event',
		component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue'),
		meta: {
			name: 'Event',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: 'Events',
			static: false,
			is_volunteer: true
		}
	},
	{
		path: '/availability/:id/',
		name: 'Availability',
		component: () => import(/* webpackChunkName: "event" */ '../views/Availability.vue'),
		meta: {
			name: 'Availability',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: 'Events',
			static: false,
			is_volunteer: true
		}
	},
	{
		path: '/series',
		name: 'Series',
		component: () => import(/* webpackChunkName: "series" */ '../views/Layout.vue'),
		meta: {
			name: 'Race Series',
			nav: true,
			landing: false,
			userNav: false,
			searchable: true,
			hideTitle: false,
			parent: false,
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/series/:id',
		name: 'Series',
		component: () => import(/* webpackChunkName: "race" */ '../views/Layout.vue'),
		meta: {
			name: 'Race Series',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: 'Series',
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/questionnaire/:id',
		name: 'Questionnaire',
		component: () => import(/* webpackChunkName: "race" */ '../views/Questionnaire.vue'),
		meta: {
			name: 'Questionnaire',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: false,
			static: false,
			is_volunteer: false
		}
	},
	{
		path: '/expense-claims/:id/claim/:claim_id',
		name: 'Claim',
		component: () => import(/* webpackChunkName: "claim" */ '../views/Claim.vue'),
		meta: {
			name: 'Expense Claim',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: false,
			static: false,
			is_volunteer: true
		}
	},
	{
		path: '/expense-claims/:id/new',
		name: 'NewClaim',
		component: () => import(/* webpackChunkName: "claim" */ '../views/NewClaim.vue'),
		meta: {
			name: 'New Expense Claim',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: false,
			static: false,
			is_volunteer: true
		}
	},
	{
		path: '/interested-in-next-event/:id',
		name: 'InterestedNextEvent',
		component: () => import(/* webpackChunkName: "claim" */ '../views/InterestedNextEvent.vue'),
		meta: {
			name: 'Interested in next event',
			nav: false,
			landing: false,
			userNav: false,
			searchable: false,
			hideTitle: true,
			parent: false,
			static: false,
			is_volunteer: true
		}
	}
]

const router = createRouter(
	{
		history: createWebHistory(process.env.BASE_URL),
		routes
	}
)

router.beforeResolve( (to, from, next) => {
	// scroll to top on navigation
	window.scrollTo( 0, 0 );

	// remove messages
	store.commit( 'message', [] );

	/*
	stop 404s
	 */
	
	let userHasAdvntnVueToken = ! isNaN( store.getters.advntm_token );
	let userIsLoggedIn = ( null !== store.getters.token && typeof store.getters.token !== 'undefined' ) && Object.values( store.getters.token ).length > 0;

	/*
	allow auth to do what it needs to
	*/
	
	if ( '/auth' === to.path ) {
		next();
		return;
	}

	/*
	important routes
	 */
	
	let login = routes.find( route => 'Login' === route.name ) ;

	// if either tokens are missing.
	if ( false === userIsLoggedIn || false === userHasAdvntnVueToken ) {
		// redirect to login.
		if ( login.path !== to.path ) {
			next( login.path )
			return;
		}
	}

	next();
	
	// if ( Object.values( to.matched ).length === 0 ) {
	// 	if ( null !== store.getters.advntm_site || 'default' !== store.getters.advntm_site ) {
	// 		next( '/' + store.getters.advntm_site + to.path );
	// 		return;
	// 	}
	// } else {

	// 	/*
	// 	if our user isn't logged in
	// 	*/
})

export default router
