<template>
	<footer class="py-5">
		<div class="container px-8">
			<div class="sm:flex">
				<p class="text-sm mb-3">
					Built by <a href="https://www.intellimatic.co.uk/" target="_blank" rel="nofollow noreferrer">Intellimatic</a> &amp; <a href="https://www.kaluna.co.uk/" target="_blank" rel="nofollow noreferrer">Kaluna</a></p>

				<p class="text-sm mb-3">
					<span class="sm:px-5 hidden sm:inline-block">|</span>Version: {{version}}
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Footer",

		data() {
			return {
				version: process.env.VUE_APP_VERSION
			}
		},
	}
</script>

<style scoped lang="scss">
	p {
		a {
			color: theme( 'colors.advntm.highlight' );
			font-weight: theme( 'fontWeight.medium' );

			&:hover {
				color: theme( 'colors.advntm.accent' );
			}
		}
	}
</style>