<template>
	<section class="loader flex items-center text-advntm-highlight">
		<div class="loader-bars flex items-center mr-3">
			<div class="loader-bar bg-advntm-highlight"></div>
			<div class="loader-bar bg-advntm-highlight"></div>
			<div class="loader-bar bg-advntm-highlight"></div>
		</div>

		<p class="font-medium" v-html="text"></p>
	</section>
</template>

<script>
	export default {
		name: 'Loader',

		props: {
			text: {
				type: String,
				default: 'Loading...'
			}
		}
	}
</script>

<style scoped lang="scss">
	/*
	keyframes
	*/

	@keyframes growShrink {
		0% {
			transform: scaleY(0.4);
		}

		50% {
			transform: scaleY(1);
		}

		100% {
			transform: scaleY(0.4);
		}
	}

	.loader-bar {
		width: 3px;
		height: 25px;
		display: inline-block;
		margin-right: .33rem;
		transform-origin: center;
		animation: growShrink 500ms ease-in-out infinite;
		transform: scaleY(0.4);

		&:nth-of-type(2) {
			animation-delay: 250ms;
		}
	}
</style>