<template>
	<nav>
		<div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 align-middle h-full">
			<router-link v-for="item in routes" :key="item.name" :to="item.path" :class="[item.current ? 'border-advntm-accent text-advntm-accent' : 'border-transparent text-mono-mid hover:border-mono-lightest hover:text-mono-lightest', 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" v-html="item.meta.name || item.name"></router-link>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'Navigation',

		data() {
			return {
				routes: false
			}
		},

		mounted() {
			if ( Object.values( this.$router.getRoutes() ).length > 0 ) {
				this.routes = [];
				this.routes = this.$router.getRoutes().filter( route => true === route.meta.nav );

				if (false === this.$store.getters.is_volunteer) {
					this.routes = this.routes.filter(route => (false === route.meta.is_volunteer || null === route.meta.is_volunteer));
				}
			}
		},

		watch: {
			$route: function() {
				if ( this.routes ) {
					this.routes.filter( route => {
						route.current = false;

						if ( route.path === this.$route.path ) {
							route.current = true;
						} else {
							route.current = false;
						}
					});
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.router-link-active {
		// color: theme( 'colors.advntm.accent' );
		// border-color: theme( 'colors.advntm.accent' );
	}

	a {
		margin-bottom: -2px;

		&:hover { 
			text-decoration: none;
		}
	}
</style>