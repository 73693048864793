<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<section class="schedule Schedule flow-root">
		<ul role="list" class="-mb-8">
			<li v-for="( event,  index ) in schedule" :key="event.id">
				<div class="relative pb-8">
					<span v-if="( index !== schedule.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-mono-darker" aria-hidden="true" />
					<div class="relative flex space-x-3">
						<div>
							<span class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-mono-darkest" :class="null !== event.made_on ? 'bg-state-success' : 'bg-state-danger'">
								<CreditCardIcon class="h-5 w-5 text-mono-darkest" />
							</span>
						</div>
						<div class="min-w-0 flex-1 flex justify-between space-x-4" :class="null === event.made_on ? 'items-baseline' : 'items-center'">
							<div>
								<div v-if="null === event.made_on">
									<p class="text-sm sm:text-lg font-medium text-mono-lightest">&pound;{{ event.amount }}</p>	
									<time class="text-sm text-mono-mid" :datetime="event.due_on">Due {{ $functions.daysUntil( event.due_on ) }}</time>
								</div>

								<p v-else class="text-sm sm:text-lg font-medium text-mono-lightest">&pound;{{ event.amount }}</p>
							</div>
							<div class="text-right text-sm whitespace-nowrap text-mono-mid">
								<time class="flex items-center" v-if="null !== event.made_on" :datetime="event.made_on"><CheckIcon class="w-5 h-5 text-state-success mr-2" /> {{ $functions.formatDate( event.made_on ) }}</time>

								<div v-else class="flex space-x-3 items-baseline">
									<!-- make this a button to trigger the payment url. -->
									<button type="button" @click.prevent="makePayment( event.id )" :id="'paymentButton_' + event.id" class="h-auto text-sm text-state-success flex items-center hover:no-underline hover:text-advntm-highlight">Make payment <ArrowNarrowRightIcon class="-mt-0.5 w-4 h-4 ml-1" /> </button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>

		<div class="payment-snapshot mt-16 pt-8 border-t border-mono-darker grid grid-cols-2 gap-6" v-if="false !== total">
			<div class="payment-snapshot__total">
				<dt class="text-sm font-medium text-mono-dark mb-2">
					Total
				</dt>
				<dd class="mt-1 text-md text-mono-lightest flex items-center">
					<p class="font-bold text-5xl">&pound;{{ total }}</p>
				</dd>
			</div>

			<div class="payment-snapshot__total">
				<dt class="text-sm font-medium text-mono-dark mb-2">
					Outstanding
				</dt>
				<dd class="mt-1 text-md text-mono-lightest flex items-center">
					<p class="font-bold text-5xl">&pound;{{ outstanding }}</p>
				</dd>
			</div>
		</div>
	</section>
</template>

<script>
	import { CreditCardIcon, CheckIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid'

	export default {
		name: 'Schedule',

		components: {
			CreditCardIcon,
			CheckIcon,
			ArrowNarrowRightIcon,
		},

		props: {
			schedule: {
				type: Array,
				default: function() {
					return [];
				}
			},
		},

		data() {
			return {
				total: false,
				Outstanding: false,
			}
		},

		methods: {
			makePayment: function( id ) {
				if ( id ) {
					let button = document.getElementById( 'paymentButton_' + id );
					button.textContent = 'Loading...';

					this.axios.post( 
						'checkout-session-url/' + id,
						{
							'payment_id': id,
							'success_url': window.location.href,
							'cancel_url': window.location.href
						}
					)
					.then( response => {
						if ( 200 === response.status ) {
							let payment_url = response.data.payment_url; 

							if ( payment_url ) {
								button.textContent = 'Redirecting...';

								setTimeout( () => {
									window.location.href = payment_url;
								}, 500 );
							}
						}
					} )
					.catch( error => {
						if ( 500 === error.response.status ) {
							this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching the payment link. Contact <a>' +  this.$functions.getSiteData('support_email', 'main') + 'us here</a> for assistance.' } );
							button.textContent = 'Make a payment';
						}

						if ( 401 === error.response.status ) {
							this.$functions.handleUnauthorised();
						}
					} );
				}
			}
		},

		mounted() {
			let amounts = this.schedule.map( item => parseFloat( item.amount ) ); 
			this.total = amounts.reduce( (a, b) => a + b, 0 );

			this.outstanding = this.schedule.map( item => null === item.made_on ? parseFloat( item.amount ) : 0 ).reduce( (a, b) => a + b, 0 );
		}
	}
</script>