<template>
	<UpdateRequired v-if="true === update_required" />

	<!-- if we're a logged in, authenticated user -->
	<div v-if="( true === $functions.isUserLoggedIn() && true === $functions.hasUserData() )">
		<section v-if="false === landing" class="app">
			<Header />

			<div class="py-8 sm:py-16">
				<main class="app-wrapper">
					<div class="container mx-auto px-8">
						<div class="inner-container max-w-5xl">
							<Breadcrumbs v-show="'Dashboard' !== $route.name || false !== $route.meta.parent" class="mb-16" />	

							<div v-if="'' !== $route.name && true !== $route.meta.hideTitle" class="mb-16 md:mb-9 md:flex justify-between items-center">
								<div class="title mb-9 md:mb-0">
									<Title tag="h1" class="font-bold" :classes="[ 'text-mono-lightest', 'text-5xl', 'mb-0' ]" :text="$route.meta.name || $route.name" />
								</div>

								<div v-if="true === searchable" class="search-form w-full md:w-6/12 lg:w-4/12">
									<form @submit.prevent="search" class="sm:flex sm:items-center">
										<label for="search_term" class="block mr-4 mb-4 sm:mb-0 sm:w-auto tracking-widest text-sm font-medium text-mono-dark">Filter:</label>
										
										<div class="flex-1 w-full flex">
											<input id="search_term" class="mr-2 focus:ring-advntm-accent focus:border-advntm-accent block w-full pl-5 pr-10 text-mono-darkest font-light" v-model="search_term" @input="search" type="search" placeholder="Search...">
											<button type="submit" title="Press to search" class="w-20 flex-initial search-form_submit bg-advntm-accent hover:bg-advntm-highlight hover:text-mono-darkest">
												<SearchIcon class="mx-auto w-6 stroke-current fill-none" />
											</button>
										</div>
									</form>
								</div>
							</div>

							<Loader v-show="false === loaded" class="mb-9" />
							
							<div v-show="null === loaded">
								<p class="text-state-info">
									<InformationCircleIcon class="h-8 w-8 text-mono-white mb-4" aria-hidden="true" />

									<span>Sorry we had an issue showing the requested page.</span>
								</p>

								<Button class="mt-6" @click.prevent="$router.go(-1)" :text="'Go Back'" />
							</div>

							<Message />

							<router-view :search_term="search_term" v-on:view_loaded="view_loaded" />
						</div>
					</div>
				</main>
			</div>

			<Footer class="mt-16" />
		</section>

		<section v-if="false === landing && ( false === $functions.isUserLoggedIn() || false === $functions.hasUserData() )">
			<Error />
		</section>

		<section v-if="true === landing" class="app">		
			<router-view v-on:view_loaded="view_loaded" />
		</section>
	</div>

	<div v-else>
		<section v-if="true === landing" class="app">		
			<router-view v-on:view_loaded="view_loaded" />
		</section>
	</div>

	<BackgroundImage id="global-theme-background" :image="$functions.getSiteData( 'body', 'background' )" />
</template>

<script>
	import { SearchIcon, InformationCircleIcon } from '@heroicons/vue/outline';

	export default {
		components: {
			SearchIcon,
			InformationCircleIcon
		},

		data() {
			return {
				search_term: null,
				landing: false,
				loaded: false,
				searchable: false,
				loading_error_message: null,
				custom_brand_colour: false,
				version: process.env.VUE_APP_VERSION,
				update_required: false
			}
		},

		mounted() {
			this.$functions.handleVolunteerPortal();

			if ( true === this.$functions.isUserLoggedIn() ) {
				// if the user data doesn't include the version number, add it. .
				if ( typeof this.$store.getters.user.VUE_APP_VERSION === 'undefined' || null === this.$store.getters.user.VUE_APP_VERSION ) {
					let user = this.$store.getters.user;
					user.VUE_APP_VERSION = this.version;

					this.$store.commit( 'user_update', user );
				} 

				// if the version number isn't correct. 
				if ( ( typeof this.$store.getters.user.VUE_APP_VERSION !== 'undefined' || null !== this.$store.getters.user.VUE_APP_VERSION ) ) { 
					if ( this.$store.getters.user.VUE_APP_VERSION !== this.version ) {
						this.update_required = true;
					}
				}
			}

			this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token;
			this.custom_brand_colour = this.$functions.getSiteData( 'colours', 'advntm-accent' );

			if ( typeof this.$functions.getSiteData( 'favicon', '16x16' ) !== 'undefined' ) {
				let Link = document.createElement( 'link' );
				Link.rel = 'icon';
				Link.href = require( '@/resources/' + this.$functions.getSiteData( 'favicon', '16x16' ) );
				Link.id = '16x16';

				document.getElementsByTagName('head')[0].append( Link );
			}

			if ( typeof this.$functions.getSiteData( 'favicon', '32x32' ) !== 'undefined' ) {
				let Link = document.createElement( 'link' );
				Link.rel = 'icon';
				Link.href = require( '@/resources/' + this.$functions.getSiteData( 'favicon', '32x32' ) );
				Link.id = '32x32';

				document.getElementsByTagName('head')[0].append( Link );
			}
		},

		methods: {
			view_loaded: function( state ) {

				this.loaded = state;
				this.loading_error_message = "Sorry, we weren't able to load " + this.$route.meta.loading_error_label;
			}
		},

		watch: {
			'$route': function() {
				this.custom_brand_colour = this.$functions.getSiteData( 'colours', 'advntm-accent' );

				let site_name = 'ADVNTM';

				if ( this.$functions.getSiteData( 'site', 'title' ) ) {
					site_name = this.$functions.getSiteData( 'site', 'title' );
				}

				document.title = typeof this.$route.meta.name !== 'undefined' ? this.$route.meta.name + ' — ' + site_name : this.$route.name + ' — ' + site_name;

				document.querySelectorAll("link[rel~='icon']").forEach( icon => {
					// if no favicon, undefined or false, exit early.
					if ( typeof this.$functions.getSiteData( 'site', 'favicon' ) === 'undefined' || false === this.$functions.getSiteData( 'site', 'favicon' ) ) {
						return false;
					}

					// change out the favicon.
					icon.href = require( '@/' + this.$functions.getResourceUrl( this.$functions.getSiteData( 'site', 'favicon' ) ) );
				});

				this.loaded = false;

				if ( false !== this.$route ) {
					this.landing = this.$route.meta.landing;
				}

				if ( false !== this.$route ) {
					this.searchable = this.$route.meta.searchable;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	label {
		cursor: pointer;
	}
</style>

<style lang="scss">
	.bg-advntm-accent {
		background-color: v-bind('custom_brand_colour') !important;
	}

	button.bg-advntm-accent:hover,
	a.bg-advntm-accent:hover {
		background-color: theme( 'colors.advntm.highlight' ) !important;
	}	

	.advntm-button {
		&:before {
			background-color: v-bind('custom_brand_colour') !important;
		}
	}

	.border-advntm-accent {
		border-color: v-bind('custom_brand_colour') !important;
	}

	.text-advntm-accent {
		color: v-bind('custom_brand_colour') !important;
	}

	*[class*="ring-advntm-accent"] {
		--tw-ring-color: v-bind('custom_brand_colour') !important
	}
</style>