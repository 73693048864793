import { createStore } from 'vuex'

export default createStore({
	state: {
		user: localStorage.getItem('user') || false,
		token: localStorage.getItem('token'),
		message: localStorage.getItem('message') || false,
		advntm_token: localStorage.getItem('advntm_token') || false,
		advntm_site: localStorage.getItem('advntm_site') || false,
		is_volunteer: localStorage.getItem('is_volunteer') || false,
	},
	mutations: {
		message( state, message ) {
			state.message = message;
			localStorage.setItem( 'message', JSON.stringify( message ) );
		},
		user_update(state, user) {
			state.user = JSON.stringify( user );
			localStorage.setItem( 'user', JSON.stringify( user ) );
		},
		is_volunteer_update(state, bool) {
			localStorage.setItem( 'is_volunteer', bool );
		},
		token(state, token) {
			state.token = token;
			localStorage.setItem( 'token', token );
		},
		advntm_token(state, token) {
			state.advntm_token = token;
			localStorage.setItem( 'advntm_token', token );
		},
		advntm_site(state, site) {
			state.advntm_site = site;
			localStorage.setItem( 'advntm_site', site );
		},
	},
	actions: {
	},
	modules: {
	},
	getters: {
		message: state => { 
			if ( typeof state.message === 'string' ) {

				if ( '[]' === state.message ) {
					return [];
				}

				return JSON.parse( state.message );
			}

			if ( typeof state.message === 'object' ) {
				return state.message;
			}
		},

		user: state => { 
			if ( typeof state.user === 'string' ) {

				if ( '[]' === state.user ) {
					return [];
				}

				return JSON.parse( state.user );
			}

			if ( typeof state.user === 'object' ) {
				return state.user;
			}
		},

		token: state => {
			if ( "false" === state.token ) {
				state.token = false;
			}

			return state.token;
		},
		advntm_token: state => parseInt( state.advntm_token ),
		advntm_site: state => state.advntm_site,
		is_volunteer: state => 'true' === state.is_volunteer ? true : false,
	}
})
