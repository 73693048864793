<template>
	<section v-if="typeof $route.name !== 'undefined'" class="layout">
		<component v-on:view_loaded="view_loaded" :is="$route.name" />
	</section>
</template>

<script>
	export default {
		methods: {
			view_loaded: function( state ) {
				this.$emit( 'view_loaded', state );
			}
		},
	}
</script>