<template>
	<section aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start message z-10">
		<div class="w-full flex flex-col items-center space-y-4 sm:items-end">
			<transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
				<div v-if="show" class="max-w-sm w-full shadow-lg pointer-events-auto ring-1 ring-mono-darkest ring-opacity-10 overflow-hidden" :class="200 === type ? 'bg-state-success' : 'bg-state-danger'">
					<div class="p-4">
						<div class="flex items-start">
							<div class="flex-shrink-0">
								<CheckCircleIcon v-if="200 === type" class="h-6 w-6 text-mono-white" aria-hidden="true" />
								<InformationCircleIcon v-else class="h-6 w-6 text-mono-white" aria-hidden="true" />
							</div>
							<div class="ml-3 w-0 flex-1 pt-0.5">
								<p v-if="typeof title !== 'undefined'" class="text-lg leading-normal font-medium text-mono-white -mt-1.5 mb-1" v-html="title"></p>
								<p class="text-sm text-mono-mono" v-html="text"></p>
							</div>
							<div class="ml-4 flex-shrink-0 flex">
								<button @click="show = false" class="h-auto inline-flex text-mono-mid hover:text-mono-mono focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mono-white">
									<span class="sr-only">Close</span>
									<XIcon class="h-5 w-5 text-mono-lightest" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</section>
</template>

<script>
	import { InformationCircleIcon, CheckCircleIcon, XIcon } from '@heroicons/vue/outline'

	export default {
		name: 'Message',

		data() {
			return {
				show: false
			}
		},

		mounted() {
			this.updateMessageData( this.$store.getters.message );
		},

		watch: {
			'$store.state.message': function( message ) {
				this.show = false;
				this.updateMessageData( message );
			}
		},

		components: {
			InformationCircleIcon,
			CheckCircleIcon,
			XIcon,
		},

		methods: {
			dismiss: function() {
				this.visible = false;
				this.$store.commit( 'message', [] );
			},

			updateMessageData: function( message ) {
				if ( typeof message.text === 'undefined' ) {
					this.title = false;
					this.text = false;
					this.type = false;
					this.visible = false;
				} else {
					this.show = true;

					let title = message.title;

					if ( 200 === message.type ) {
						title = 'Success';
					}

					if ( 204 === message.type ) {
						title = 'Notice';
					}

					if ( 200 !== message.type ) {
						title = 'Error';
					}

					this.title = title;
					this.text = message.text;
					this.type = message.type;
					this.visible = true;
				}
			}
		}
	}
</script>