<template>
	<section v-if="false !== questionnaire" class="questionnaire">
		<div class="questionnaire-header mb-9">
			<Title tag="h1" :text="questionnaire.category" :classes="[ 'text-mono-lightest', 'text-5xl', 'mb-5' ]" /> 
			
			<div class="flex">
				<div>
					<p class="text-sm">Status: <strong v-html="questionnaire.status"></strong></p>
				</div>

				<div v-if="questionnaire.due_on" class="ml-4">
					<p class="text-sm">Due in: <strong><time v-html="$functions.daysUntil( questionnaire.due_on )"></time></strong></p>
				</div>
			</div>
		</div>

		<form @submit.prevent="update" v-if="Object.values( questionnaire.questions ).length > 0" class="space-y-8 divide-y divide-mono-dark">
			<div v-for="question in Object.values( questionnaire.questions )" :key="question.id" class="space-y-6 sm:space-y-5">
				<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-dark sm:pt-5">
					<label :for="'question_' + question.id" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" v-html="question.question_text"></label>

					<div class="mt-1 sm:mt-0 sm:col-span-2">
						<div v-if="'Radio' === question.response_type && return_options( question.response_options )" class="radios mt-4 space-y-4">
							<div class="flex items-center" v-for="( radio, index ) in return_options( question.response_options )" :key="index">
								<input :checked="question.response == radio" :id="'question_' + question.id + '_' + radio" type="radio" :name="question.id" :value="radio" class="focus:ring-brand-accent h-4 w-4 text-brand-accent border-mono-dark">

								<label :for="'question_' + question.id + '_' + radio" class="ml-3 block text-sm font-medium text-mono-mid" v-html="radio"></label>
							</div>
						</div>

						<div v-if="'Checkboxes' === question.response_type && return_options( question.response_options )" class="checkboxes mt-4 space-y-4">
							<div class="flex items-center" v-for="( checkbox, index ) in return_options( question.response_options )" :key="index">
								<input :checked="check_for_options_in_response( checkbox, question.response )" @change="collect_checkbox_responses( $event, question.question_text, checkbox )" :id="'question_' + question.id + '_' + checkbox" type="checkbox" :value="checkbox" class="focus:ring-brand-accent h-4 w-4 text-brand-accent border-mono-dark">

								<label :for="'question_' + question.id + '_' + checkbox" class="ml-3 block text-sm font-medium text-mono-mid" v-html="checkbox"></label>
							</div>
						</div>

						<div v-if="'Multi-select' === question.response_type && return_options( question.response_options )" class="checkboxes mt-4 space-y-4">
							<select class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light" :name="'question_' + question.id" :id="'question_' + question.id + '_' + checkbox" v-model="question.response">
								<option value="">-- Select --</option>

								<option v-for="( option, index ) in return_options( question.response_options )" :key="index" :value="option" v-html="option"></option>
							</select>
					</div>

					<div v-if="'Input' === question.response_type" class="input mt-4 space-y-4">
						<input type="text" v-model="question.response" :id="'question_' + question.id" autocomplete="given-name" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
					</div>
				</div>
			</div>
		</div>

		<div class="pt-5">
			<div class="flex justify-between align-middle">
				<div></div>
				
				<Button ref="updateQuestionnaire" id="updateQuestionnaire" :text="buttonText" />
			</div>
		</div>
	</form>
</section>
</template>

<script>
	export default {
		data() {
			return {
				questionnaire: false,
				buttonText: 'Submit',
				checkboxModel: []
			}
		},

		methods: {
			check_for_options_in_response: function( option, response ) {
				if ( null === response ) {
					return false;
				}

				let pieces = response.split(',');

				if ( Object.values( pieces ).length > 0 ) {
					pieces = Object.values( pieces ).map( piece => piece.trim() );
				}

				return pieces.indexOf( option ) >= 0;
			},

			return_options: function( options ) {
				if ( "" === options ) {
					return false;
				}

				let pieces = options.split(',');

				if ( Object.values( pieces ).length > 0 ) {
					pieces = Object.values( pieces ).map( piece => piece.trim() );
				}

				return pieces;
			},

			collect_checkbox_responses: function( event, question_text, option ) {
				if ( ! option ) {
					return;
				}

				if ( typeof this.checkboxModel[ question_text ] === 'undefined' ) {
					this.checkboxModel[ question_text ] = [];
				}

				if ( true === event.srcElement.checked ) {
					if ( this.checkboxModel[ question_text ].indexOf( option ) < 0 ) {
						this.checkboxModel[ question_text ].push( option );
					}
				} 

				if ( false === event.srcElement.checked ) {
					if ( this.checkboxModel[ question_text ].indexOf( option ) >= 0 ) {
						delete this.checkboxModel[ question_text ][ this.checkboxModel[ question_text ].indexOf( option ) ];
					}
				} 
			},

			update() {
				this.buttonText = "Submitting...";
				
				if ( document.getElementById( 'updateQuestionnaire' ) ) {
					document.getElementById( 'updateQuestionnaire' ).setAttribute( 'disabled', true );
				}

				this.questionnaire.questions.map( question => {
					// if it isn't a checkbox, don't use the checkbox mapping.
					if ( 'Checkboxes' !== question.response_type ) {

						if ( 'Radio' === question.response_type && "" !== question.response_options ) {
							if ( document.querySelector( 'input[name="' + question.id + '"]' ) ) {
								if ( null !== document.querySelector( 'input[name="' + question.id + '"]:checked' ) ) {
									question.response = document.querySelector( 'input[name="' + question.id + '"]:checked' ).value;
								}
							}
						}

						return;
					}

					if ( typeof this.checkboxModel[ question.question_text ] === 'undefined' ) {
						return;
					}

					if ( Object.values( this.checkboxModel[ question.question_text ] ) <= 0 ) {
						return false;
					}

					question.response = this.checkboxModel[ question.question_text ].join( ',' );
				} );

				this.axios.post( 
					'competitor-portal/submit-questionnaire/', 
					{
						questionnaireId: parseInt( this.$route.params.id ),
						questions: this.questionnaire.questions
					} 
				)
				.then( () => {
					this.$store.commit( 'message', { type: 200, text: 'Awesome, you have successfully updated this questionnaire' } );

					if ( document.getElementById( 'updateQuestionnaire' ) ) {
						document.getElementById( 'updateQuestionnaire' ).removeAttribute( 'disabled' );
					}

					this.buttonText = "Submit";
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem updating this questionnaire.' } );
				} );
			}
		},

		mounted() {
			this.axios.get( 'competitor-portal/race/' + this.$route.query.race_id + '/questionnaire/' + this.$route.query.category.toLowerCase() )
			.then( response => {
				if ( 200 === response.status && parseInt( this.$route.params.id ) === parseInt( response.data.data.id ) ) {
					this.questionnaire = response.data.data;

					console.log( this.questionnaire );

					if ( false !== this.questionnaire ) {
						this.$emit( 'view_loaded', true );
					}

					// construct the checkbox object for submission.
					this.questionnaire.questions.map( question => {
						if ( 'Checkboxes' !== question.response_type ) {
							return;
						}

						if ( null === question.response ) {
							return;
						}

						let question_responses = question.response.split(',');

						question_responses.filter( item => {
							if ( typeof this.checkboxModel[ question.question_text ] === 'undefined' ) {
								this.checkboxModel[ question.question_text ] = [];
							}

							this.checkboxModel[ question.question_text ].push( item );
						} );
					} );
				}
			} )
			.catch( error => {
				if ( 401 === error.response.status ) {
					this.$functions.handleUnauthorised();
				}

				this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching this questionnaire.' } );
			} );
		}
	}
</script>

<style lang="scss" scoped>
	input {
		color: theme( 'colors.mono.darkest' );
	}
</style>