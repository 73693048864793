<template>
	<article v-if="Object.values( race ).length > 0" class="Race race p-5 bg-mono-darker bg-opacity-50">
		<div class="sm:flex items-center justify-between">
			<div>
				<router-link class="text-mono-lightest text-2xl inline-block mb-3" :to="{ name: 'Event', params: { id: race.id } }" v-html="race.event.name" />

				<div class="sm:flex items-start -mb-2">
					<div class="race-detail mb-2">
						<p class="mb-0 flex items-start text-sm text-mono-mid sm:mt-0 sm:mr-6">
							<LocationMarkerIcon class="flex-shrink-0 mt- mr-1.5 h-5 w-5 text-mono-mid" aria-hidden="true" />
							<span>{{ race.event.location }}, {{ race.event.country }}</span>
						</p>
					</div>
					<div class="race-detail mb-2">
						<p class="mb-0 flex items-start text-sm text-mono-mid sm:mt-0 sm:mr-6">
							<CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-mono-mid" aria-hidden="true" />
							<time class="text-sm sm:text-md font-light text-mono-mid">{{ $functions.formatDate( race.event.start_date ) }}</time>
						</p>
					</div>
				</div>
			</div>
			
			<div>
				<div class="race-anchor mt-7 sm:mt-0 sm:mt-0">
					<router-link class="sm:block race-button bg-advntm-accent px-4 mb-0 py-3 text-mono-lightest text-md hover:bg-advntm-highlight hover:no-underline hover:text-mono-darkest" :to="{ name: 'Event', params: { id: race.id } }" v-html="'View event &rarr;'" />
				</div>
			</div>
		</div>
	</article>
</template>

<script>
	import { CalendarIcon, LocationMarkerIcon } from '@heroicons/vue/outline';

	export default {
		name: 'Event',

		components: {
			CalendarIcon,
			LocationMarkerIcon
		},

		props: {
			race: {
				type: Object,
				default: function() {
					return {};
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.race-detail {
		max-width: 400px;

		svg {
			margin-top: 2.5px;
		}
	}
</style>