<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-mono-darkest bg-opacity-80 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="relative inline-block align-bottom bg-mono-darkest px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 border border-mono-darker">
						<div class="sm:flex sm:items-start">
							<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 bg-state-danger rounded-full sm:mx-0 sm:h-10 sm:w-10">
								<ExclamationIcon class="h-6 w-6 text-mono-darkest" aria-hidden="true" />
							</div>
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<DialogTitle as="h3" class="text-2xl leading-6 font-medium text-mono-lightest mb-5" v-html="title"></DialogTitle>
								<div class="mt-2">
									<p class="text-md text-mono-light" v-html="description"></p>
								</div>
							</div>
						</div>
						<div class="mt-5 sm:mt-9 sm:flex sm:flex-row-reverse">
							<button type="button" class="h-auto w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-state-danger text-base font-medium text-white hover:opacity-50 focus:ring-4 focus:ring-offset-4 ring-offset-mono-darkest focus:ring-state-danger sm:ml-3 sm:w-auto sm:text-sm" @click="open = false; action = 'confirm'" v-html="confirm"></button>

							<button type="button" class="h-auto mt-3 w-full inline-flex justify-center border border-mono-darker shadow-sm px-4 py-2 bg-white text-base font-medium text-mono-mid hover:bg-mono-darker focus:ring-4 focus:ring-offset-4 ring-offset-mono-darkest focus:ring-mono-darker sm:mt-0 sm:w-auto sm:text-sm" @click="open = false; action = 'cancel'" ref="cancelButtonRef">Cancel</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
	import { ExclamationIcon } from '@heroicons/vue/outline'

	export default {
		name: 'Alert',

		components: {
			Dialog,
			DialogOverlay,
			DialogTitle,
			TransitionChild,
			TransitionRoot,
			ExclamationIcon,
		},

		data() {
			return {
				action: false,
				open: false
			}
		},

		props: {
			is_open: {
				type: Boolean,
				default: false
			},

			id: {
				type: String,
				default: 'alertId'
			},
			title: {
				type: String,
				default: 'The title'
			},

			description: {
				type: String,
				default: 'Lorem, ipsum dolor sit amet, consectetur adipisicing elit. Culpa repellat nesciunt repellendus, soluta, quos numquam.'
			},

			confirm: {
				type: String,
				default: 'Confirm'
			},
		},

		watch: {
			'is_open' : function( state ) {
				this.open = state;
			},

			'open': function( open ) {
				if ( false === open ) {
					this.$emit( 'alert_dismissed', this.id, this.action );
				}
			}
		}
	}
</script>