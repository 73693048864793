import { createApp } from 'vue'
import App from './App.vue'
import sites from '@/data'
const app = createApp(App);

/*
PWA
*/
import './registerServiceWorker'

/*
router
*/
import router from './router'
app.use(router);

/*
store
*/
import store from './store'
app.use(store);

/*
functions
 */

app.config.globalProperties.$functions = {
    sanitize: function(string) {
        if ( ! string ) {
            return false;
        }

        string = string.toLowerCase();

        return string.replaceAll( ' ', '-' );
    },
    unsanitize: function(string) {
        if ( ! string ) {
            return false;
        }

        string = string.toLowerCase();
        string = string.replaceAll('_', ' ');

        return string;
    },
    isUserLoggedIn: function() {
        return ( null !== store.getters.token && typeof store.getters.token !== 'undefined' ) && Object.values( store.getters.token ).length > 0;
    },
    hasUserData: function() {
        return ( false !== store.getters.user && typeof store.getters.user !== 'undefined' ) && Object.values( store.getters.user ).length > 0;
    },
    formatDateTime: function( date ) {
        return moment( date ).format('Do MMM YYYY, h:mma');
    },
    formatDate: function( date ) {
        return moment( date ).format('Do MMM YYYY');
    },
    formatDateAlt: function( date ) {
        return moment( date ).format('YYYY-MM-DD');
    },
    daysUntil: function( date ) {
        return moment().to( moment( date ) );
    },
    days: function( date ) {
        return moment.duration( moment( date ).diff( moment() ) ).asDays();
    },
    timestamp: function( date ) {
        return moment( date ).unix();
    },
    getResourceUrl: function( resource ) {
        return 'resources/' + resource;
    },
    getCurrentSite: function() {
        let site;

        if ( typeof sites !== 'undefined' ) {
            let find = Object.values( sites ).find( site => site.allowed_domains.indexOf( window.location.host ) >= 0 );

            if ( typeof find === 'undefined' ) {
                site = sites['default'];
            }

            if ( typeof find !== 'undefined' && Object.values( find ).length > 0 ) {
                site = find;
            }
        }

        if ('undefined' !== typeof store.getters.is_volunteer && true === store.getters.is_volunteer) {
            site = Object.values( sites ).find( sub_site => ('undefined' !== typeof sub_site.has_volunteer_portal && true === sub_site.has_volunteer_portal ) && window.location.origin.replace('https://', '').replace('http://', '') === sub_site.domain.volunteer_portal );

            if (site) {
                site = sites[ 'volunteer.' + site.site.name ];
                site.is_volunteer_portal = true;
            }
        }

        return site;
    },
    getVolunteerSite: function() {
        let site = this.getCurrentSite();

        if ('undefined' !== typeof site.has_volunteer_portal && true === site.has_volunteer_portal) {
            site = sites[ 'volunteer.' + site.site.name ];
        }

        return site;
    },
    getSiteData: function( location, attribute ) {
        let site = this.getCurrentSite();

        // check if our location exists.
        if ( typeof site[ location ] === 'undefined' ) {
            return false;
        }

        // check for the attribute, in the specified location.
        if ( typeof site[ location ][ attribute ] === 'undefined' ) {
            return false;
        }

        return site[ location ][ attribute ];
    },
    handleVolunteerPortal: function() {
        let subdomain = window.location.origin.split('.')[0];
        subdomain = subdomain.replaceAll(/^https?:\/\//g, '');

        if (typeof subdomain !== 'undefined' && 'volunteer' === subdomain) {
            store.commit( 'is_volunteer_update', true );
        } else {
            store.commit( 'is_volunteer_update', false );
        }
    },
    handleUnauthorised: function() {
        store.commit( 'user_update', [] );
        store.commit( 'token', false );
        store.commit( 'message', { type: 401, text: 'Please login again, your session has expired.' } );

        setTimeout( () => {
            router.push( '/login' );
        }, 1000 )
    },
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
}

/*
svgs
*/
import svg from 'vue-inline-svg';
app.component('inline-svg', svg);

/*
moment
*/
import moment from 'moment';
app.use(moment);

/*
axios
*/
import axios from 'axios'
import vue_axios from 'vue-axios'
app.use( vue_axios, axios );
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

/*
theme
*/
import './index.css'
import './assets/tailwind.css'

/*
components
*/
import components from '@/components';

Object.values( components ).forEach( component => {
    app.component(component.name, component);
} );

/*
mount
*/
app.mount('#app');
