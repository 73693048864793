<template>
	<transition v-if="( null !== routes && Object.values( routes ).length > 0 )" enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
		<MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-mono-darkest focus:outline-none">
			<MenuItem v-for="item in routes" :key="item.name">
				<router-link :to="item.path" :class="[item.current ? 'text-advntm-accent' : '', 'block px-4 py-2 text-sm text-mono-lightest']" v-html="item.name"></router-link>
			</MenuItem>

			<button @click="logout" class="block px-4 py-2 text-sm font-bold leading-normal h-auto text-mono-lightest" type="button">Logout &rarr;</button>
		</MenuItems>
	</transition>
</template>

<script>
	import { MenuItem, MenuItems } from '@headlessui/vue'

	export default {
		name: 'UserNavigation',

		data() {
			return {
				routes: null
			}
		},

		components: {
			MenuItem,
			MenuItems
		},

		mounted() {
			if ( Object.values( this.$router.getRoutes() ).length > 0 ) {
				this.routes = [];
				this.routes = this.$router.getRoutes().filter( route => true === route.meta.userNav );

				if (true === this.$store.getters.is_volunteer) {
					this.routes = this.routes.filter(route => (true === route.meta.is_volunteer || null === route.meta.is_volunteer));
				}
			}
		},

		methods: {
			logout: function() {
				if ( confirm( 'Are you sure you want to logout?' ) ) {
					localStorage.removeItem( 'user' );	
					localStorage.removeItem( 'advntm_token' );	
					localStorage.removeItem( 'token' );	

					setTimeout( () => {
						this.$router.push( '/login' );
					}, 2000 );
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	a.router-link-exact-active {
		color: theme( 'colors.advntm.highlight' );
	}

	a:hover {
		text-decoration: none;
		color: theme( 'colors.mono.mid' );
	}
</style>