<template>
	<section>
		<h1 class="text-3xl font-bold underline text-advntm-accent">Welcome to ADVNTM!</h1>
	</section>
</template>

<script>
	export default {
		name: 'Welcome',
	}
</script>