<template>
	<section class="error flex items-center">
		<div class="container mx-auto sm:w-100 md:w-8/12 xl:w-4/12 px-4 text-center">
			<div class="w-auto mx-auto">
				<inline-svg class="mb-24 w-24 ml-auto mr-auto" :src="require( '@/assets/images/svg/advntm-yellow.svg' )" />
			</div>

			<div class="mb-9">
				<Title tag="h1" :text="'Uh oh, we are very sorry...'" />
			</div>

			<p>Woops, there has been an error fetching your details. Contact a race director for more information. Alternatively, contact <a href="mailto:support@kaluna.co.uk" title="Contact support">support</a>.</p>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Error'
	}
</script>

<style scoped lang="scss">
	.error {
		height: 100vh;
	}
</style>