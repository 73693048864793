<template>
	<section v-if="Object.values( stats ).length > 0" class="stats border-b border-mono-darker pb-9">
		<Title tag="h2" text="Your Race Statistics" :classes="[ 'text-advntm-highlight', 'text-lg', 'mb-9' ]" /> 

		<dl class="grid grid-cols-1 gap-5 sm:grid-cols-4">
			<div v-for="item in stats" :key="item.name">
				<dt class="text-sm font-medium text-mono-dark truncate">
					{{ item.name }}
				</dt>
				<dd class="mt-1 text-4xl font-bold text-advntm-highlight">
					<span v-html="item.stat"></span>
					
					<span class="ml-2 text-sm text-mono-light" v-if="false !== item.unit" v-html="item.unit"></span>
				</dd>
			</div>
		</dl>
	</section>

	<div v-if="Object.values( link ).length > 0" class="stats-link mt-5 w-full sm:w-3/12">
		<Link class="w-auto" :link="link" />
	</div>
</template>

<script>

	export default {
		name: 'Stats',

		props: {
			stats: {
				type: Array,
				default: function() {
					return [];
				}
			},

			link: {
				type: Array,
				default: function() {
					return [];
				}
			}
		}
	}
</script>