<template>
	<section>
		<header class="pb-8">
			<div class="md:flex items-center md:space-x-5 space-y-5 md:space-y-0 justify-between">
				<div class="mb-1">
					<h1 class="text-4xl font-bold text-mono-lightest mb-2 flex items-center">
						<router-link title="Back to event" class="inline-block text-mono-dark hover:text-mono-lightest mr-4" :to="{ name: 'Event', params: { id: $route.params.id } }">
							<ArrowLeftIcon class="mt-0.5 h-6 w-6 flex-none text-sm font-medium text-current" aria-hidden="true" />
						</router-link>

						New Expense Claim
					</h1>
				</div>
			</div>
		</header>

		<form @submit.prevent="submitClaim">
			<div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
				<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
					<div class="mb-9">
						<h3 class="text-lg font-medium text-mono-lightest">Submit a new expense claim</h3>
					</div>

					<div class="space-y-6 sm:space-y-5">
						<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
							<label for="amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Amount <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
							</label>
							<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
								<input required type="number" step="any" min="0.00" placeholder="0.00" v-model="claim.amount" id="amount" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
							</div>
						</div>
					</div>

					<div class="space-y-6 sm:space-y-5">
						<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
							<label for="description" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Description <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
							</label>
							<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
								<textarea v-model="claim.description" id="description" class="block w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent border-mono-lightest"></textarea>
							</div>
						</div>
					</div>

					<div class="space-y-6 sm:space-y-5">
						<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
							<label for="category" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Category <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
							</label>
							<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
								<Select label="category" :options="categories" v-model="claim.category" :value="claim.category" />
							</div>
						</div>
					</div>

					<div class="space-y-6 sm:space-y-5">
						<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
							<label for="file-upload" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Upload a file <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
							</label>

							<div class="mt-2 flex col-span-2 justify-center border border-dashed border-gray-900/25 px-6 py-10">
								<div class="text-center">
									<PaperClipIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />

									<div class="mt-4 flex text-sm leading-6 text-gray-600">
										<label for="file-upload" class="relative cursor-pointer font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
											<span class="text-advntm-accent font-medium" v-html="(typeof claim.file === 'undefined' || false === claim.file) ? 'Upload a new file' : claim.file.name"></span>
											<input @change="getFile" id="file-upload" name="file-upload" type="file" class="sr-only" />
										</label>
										<p v-if="(typeof claim.file === 'undefined' || false === claim.file)" class="pl-1">or drag and drop</p>
										<p v-if="typeof claim.file !== 'undefined' && false !== claim.file" class="pl-1">
											<button type="button" @click="removeFile" class="text-mono-lightest h-auto w-auto text-sm ml-4">
												<XCircleIcon class="inline-block mx-auto h-6 w-6 text-state-danger" />

												Remove file
											</button>
										</p>
									</div>

									<p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
								</div>
							</div>
						</div>
					</div>

					<div class="space-y-6 sm:space-y-5 pt-8">
						<div class="border-t border-mono-darker pt-5">
							<Button text="Send expense claim" />
						</div>
					</div>
				</div>
			</div>
		</form>
	</section>
</template>

<script>
	import { PaperClipIcon, XCircleIcon, ArrowLeftIcon } from '@heroicons/vue/solid';

	export default {
		data() {
			return {
				claim: [],
				categories: [
					{
						'value': 'travel_costs',
						'name': 'Travel Costs'
					},
					{
						'value': 'train',
						'name': 'Train'
					},
					{
						'value': 'food',
						'name': 'Food'
					},
					{
						'value': 'equipment',
						'name': 'Equipment'
					}
				]
			}
		},

		components: {
			PaperClipIcon,
			ArrowLeftIcon,
			XCircleIcon
		},

		methods: {
			getFile: function(event) {
				this.claim.file = event.target.files[0];
			},

			removeFile: function() {
				this.claim.file = false;
				
				let file = document.getElementById('file-upload');

				if (file) {
					file.value = null;
				}
			},

			submitClaim: function() {
				let formData = new FormData();

				formData.append('volunteerId', this.$route.params.id);
				formData.append('amount', this.claim.amount);
				formData.append('description', this.claim.description);
				formData.append('category', this.claim.category);
				formData.append('file', this.claim.file);

				this.axios.post(
					'volunteer-portal/expense-claims',
					formData
				)
				.then( response => {
					if (200 === response.status) {
						this.$store.commit( 'message', { type: 200, text: response.data } );
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem submitting this event.' } );
				} );
			}
		},

		mounted() {
			this.$emit( 'view_loaded', true );
		}
	}
</script>