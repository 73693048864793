<template>
	<button :id="id" class="advntm-button text-advntm-accent flex justify-between items-center text-xl border-b-4 border-b-advntm-accent focus:outline-none shadow-sm focus:ring-4 focus:ring-offset-4 ring-offset-mono-darkest focus:ring-advntm-accent" type="submit">
		<span v-html="text"></span>
	</button>
</template>

<script>
	export default {
		name: 'Button',

		props: [ 'id', 'text' ]
	}
</script>

<style scoped lang="scss">
	button {
		text-align: left;
		border-top: 4px solid transparent;

		span {
			display: inline-block;
			vertical-align: middle;
		}

		&:after {
			content: '\2192';
			margin-left: auto;
			display: inline-block;
			vertical-align: middle;
			margin-left: 1rem;
		}

		&:not(:disabled) {
			&:hover {
				border-color: transparent;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	/*
	a11y
	*/
	@media screen and (prefers-reduced-motion: no-preference) {
		button {
			transition: padding ease-in-out 250ms, ease-in-out 250ms;
			position: relative;
			z-index: 0;

			span,
			&:after {
				transition: inherit;
			}

			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				bottom: -4px;
				left: 0;
				width: 100%;
				height: calc( 100% + 8px );
				transform: scaleY(0);
				transition: transform ease-in-out 250ms;
				transform-origin: bottom;
				background-color: theme( 'colors.advntm.accent' );
			}

			&:not(:disabled) {
				&:hover {
					span,
					&:after {
						color: theme( 'colors.mono.lightest' );
					}

					&:before {
						transform: scaleY(1);
					}
				}

				&:focus-visible {
					outline: none
				}
			}
		}
	}

	@media screen and (prefers-reduced-motion: reduce) {
		button {
			box-shadow: none;

			&:hover {
				background: theme( "colors.advntm.accent" );
			}
		}
	}
</style>