<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="flex flex-col">
		<Title tag="h2" text="Your Races" :classes="[ 'text-mono-lightest', 'text-2xl', 'mb-9' ]" />
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-mono-light sm:rounded-lg">
					<table class="min-w-full divide-y divide-mono-light">
						<thead class="bg-mono-lighter">
							<tr>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-mono-dark uppercase tracking-wider">
									ID
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-mono-dark uppercase tracking-wider">
									Name
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-mono-dark uppercase tracking-wider">
									Date
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-mono-dark uppercase tracking-wider">
									Status
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-mono-dark uppercase tracking-wider">
									Next payment
								</th>
							</tr>
						</thead>
						<tbody class="bg-mono-lightest divide-y divide-mono-light">
							<tr v-for="race in races" :key="race.id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-mono-darkest">{{ race.id }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-mono-darkest">{{ race.title }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-mono-dark">
									{{ race.date }}
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-mono-dark">
									{{ race.status }}
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-mono-dark">
									{{ race.nextPayment }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Table',

		data() {
			return {
				races: [
					{
						id: '2567',
						title: 'The Ice Ultra',
						status: 'Upcoming',
						date: '24/02/2023',
						nextPayment: '24/02/2022',
					},
					{
						id: '9875',
						title: 'The Jungle Ultra',
						status: 'Completed',
						date: 'N/A',
						nextPayment: 'N/A',
					},
				]
			}
		}
	}
</script>