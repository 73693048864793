<template>
	<component :is="tag" v-html="text" :class="Object.values( classes ).length > 0 ? classes.join( ' ' ) : null"></component>
</template>

<script>
	export default {
		name: 'Title',

		props: {
			tag: {
				type: String,
				default: 'h1'
			},

			text: {
				type: String,
				default: ''
			},

			classes: {
				type: Array,
				default: function() {
					return [ 'text-mono-lightest', 'text-5xl', 'mb-7' ];
				}
			}
		}
	}
</script>