<template>
	<section></section>	
</template>

<script>
	export default {
		mounted() {
			this.axios.put( 'volunteer-portal/' + this.$route.params.id + '/interested-in-next-event' )
			.then( response => {
				if (200 === response.status) {
					this.$router.push({ name: 'Event', params: { id: this.$route.params.id }, query: { interested: response.data } });
				}
			} )
			.catch( error => {
				if ( 401 === error.response.status ) {
					this.$functions.handleUnauthorised();
				}

				this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem registering your interested for the next event.' } );
			} );
		}
	}
</script>