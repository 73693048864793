<template>
	<section v-if="false !== event" class="event min-h-full">
		<header class="pb-8">
			<div class="md:flex items-center md:space-x-5 space-y-5 md:space-y-0 justify-between">
				<div class="mb-1">
					<h1 class="text-4xl font-bold text-mono-lightest mb-2 flex items-center">
						<router-link title="Back to event" class="inline-block text-mono-dark hover:text-mono-lightest mr-4" :to="{ name: 'Event', params: { id: $route.params.id } }">
							<ArrowLeftIcon class="mt-0.5 h-6 w-6 flex-none text-sm font-medium text-current" aria-hidden="true" />
						</router-link>

						Your availability for {{event.event.name}}
					</h1>
				</div>
			</div>
		</header>

		<form @submit.prevent="request_availability_change" class="pb-12">
			<div class="border-t border-mono-darker pt-7">
				<dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 items-center">
					<div class="race-detail col-span-3">
						<dt class="text-sm font-medium text-mono-dark mb-2 inline-flex items-center pb-5">
							<CalendarIcon class="flex-shrink-0 mr-2 h-8 w-8" aria-hidden="true" />

							Toggle your availability for the dates/times below.
						</dt>

						<dd class="mt-1 text-md text-mono-lightest grid items-center">
							<div class="col-span-2 grid grid-cols-2 gap-x-4 gap-y-6 items-center">
								<div class="inline-flex items-center" :class="1 === slot.is_available || true === form[slot.id] ? 'text-state-success' : 'text-state-danger'" v-for="(slot, index) in slots" :key="index">
									<label :data-id="slot.id">
										<CheckCircleIcon v-if="1 === slot.is_available || true === form[slot.id]" class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
										<XCircleIcon v-else class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />

										<span>
											{{slot.availability_date}}

											<span v-if="slot.slot.includes('am')">(Day shift)</span>
											<span v-else-if="slot.slot.includes('pm')">(Night shift)</span>
										</span>

										<input :checked="1 === slot.is_available" @change="update_slot_status" :data-slot="slot.id" class="hidden" type="checkbox" v-model="form[slot.id]">
									</label>
								</div>
							</div>	
						</dd>
					</div>
				</dl>
			</div>

			<div class="mt-8 col-span-3">
				<Button id="requestAvailabilityChange" text="Submit Request" />
			</div>
		</form>
	</section>
</template>

<script>
	import { ArrowLeftIcon, CalendarIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline';

	export default {
		data() {
			return {
				event: false,
				form: [],
				slots: []
			}
		},

		components: {
			ArrowLeftIcon,
			CalendarIcon,
			CheckCircleIcon,
			XCircleIcon,
		},

		mounted() {
			this.axios.get( 'volunteer-portal/events/' + this.$route.params.id )
			.then( response => {
				this.event = response.data.volunteer;
				this.slots = this.event.availability_slots;

				this.$emit( 'view_loaded', true );
			} )
			.catch( error => {
				if ( 401 === error.response.status ) {
					this.$functions.handleUnauthorised();
				}

				this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching this event.' } );
				this.$emit( 'view_loaded', null );
			} );
		},

		methods: {
			update_slot_status: function() {
				let slot_id = event.target.getAttribute('data-slot');
				let slot 	= Object.values(this.slots).find(slot => parseInt(slot.id) === parseInt(slot_id));

				if (true === event.target.checked) {
					slot.is_available = 1;
				}

				if (false === event.target.checked) {
					slot.is_available = 0;
				} 
			},

			request_availability_change: function() {
				// this.form
				this.event.availability_slots.forEach(slot => {
					let keys = Object.keys(this.form).map(item => parseInt(item));
					let index = keys.indexOf(slot.id);

					if (index === -1) {
						return;
					}

					if (typeof Object.values(this.form)[index] === 'undefined') {
						return;
					}

					if (false === Object.values(this.form)[index]) {
						slot.is_available = 1;
					}

					slot.is_available = 0;
				});

				this.axios.post( 
					'volunteer-portal/availability-changes',
					{
						volunteer_id: this.$route.params.id,
						availability_data: this.event.availability_slots
					}
				)
				.then( response => {
					if (200 === response.status) {
						this.$store.commit( 'message', { type: 200, text: 'Your availability change was successfully requested.' } )
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem updating your availability for this event.' } );
					this.$emit( 'view_loaded', null );
				} );
			}
		}
	}
</script>

<style lang="scss" scoped>
	label {
		cursor: pointer;
	}
</style>