<template>
	<div class="Select max-w-lg w-full" v-if="Object.values( options ).length > 0">
		<select ref="select" @input="handleInput" v-if="'simple' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light">
			<option value="">-- Make a selection --</option>

			<option :selected="value === option" v-for="( option, index ) in options" :key="index">
				{{option}}
			</option>
		</select>

		<select ref="select" @input="handleInput" v-if="'multidimensional' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light">
			<option value="">-- Make a selection --</option>

			<option :value="option.value" :selected="value === option.value" v-for="( option, index ) in options" :key="index">
				{{ option.name }}
			</option>
		</select>
	</div>
</template>

<script>
	export default {
		name: 'Select',

		emits: [ 'update:modelValue' ],

		props: {
			options: { 
				type: Array,
				default: function() {
					return [];
				}
			},

			value: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				type: 'simple'
			}
		},

		mounted() {
			if ( this.options ) {
				Object.values( this.options ).forEach( option => {
					if ( typeof option === 'string' ) {
						return;
					}

					if ( typeof option === 'object' ) {
						this.type = 'multidimensional';
					}
				} );
			}
		},

		methods: {
			handleInput: function() {
				// this.value = this.$refs.select.value;

				if ( "" !== this.$refs.select.value ) {
					this.$emit('update:modelValue', this.$refs.select.value );
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	select {
		height: 60px;

		option {
			color: #000;
		}
	}
</style>